<template>
    <section id="header">
        <div class="content">
            <img class = "logo-image" src="@/assets/logo_transparent.png" />
            <h1>Ride Sharing for a cheaper ride</h1>
            <p>City to City carpooling made easy. Shareride and earn money.</p>

            <div class="mt-3 app-download-buttons">
                <a href="https://apps.apple.com/app/nride-carpool-rental/id1607959386" target="_blank">
                <img src="@/assets/images/app/appstore.png" />
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.neighbours.nride" target="_blank">
                <img src="@/assets/images/app/google_play.png" />
                </a>
            </div>

            <span class="more" @click="handleClick()">Learn more about this application</span>
        </div>
    </section>

    <section id="carpool" class="container">
        <div class="row" ref="targetRef">

            <div class="col-md-4 content">
                <img src="@/assets/images/share/money.png">
                <h5>Your pick of rides at low prices</h5>
                <p class="subtitle">No matter where you’re going, find the perfect ride from our wide range of destinations and routes at low prices.</p>

            </div>

            <div class="col-md-4 content">
                <img src="@/assets/images/share/trust.png">
                <h5>Trust who you travel with</h5>
                <p class="subtitle">We take the time to get to know each of our member profiles and IDs, so you are safe when you’re travelling with us.</p>

            </div>

            <div class="col-md-4 content">
                <img src="@/assets/images/share/smartphone.png">
                <h5>Scroll, click, tap and go!</h5>
                <p class="subtitle">Booking a ride has never been easier! Thanks to our simple app powered by great technology, you can book a ride close to you in just minutes.</p>

            </div>

        </div>

    </section>

  <!-- <section id="services" class="container">
        <div class="row service">
            <div class="col-md-6 content">
                <h1>How to use the app?</h1>
                <p class="subtitle">See our tutorial video on how to use the app to book a shared ride</p>
                <a href="" class="more">Learn More about Rentals</a>
            </div>
            <div class="col-md-6">
                <div class="embed-responsive embed-responsive-16by9" poster="@/assets/images/share/smartphone.png">
                <iframe height="400" class="w-100 vid-height" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowfullscreen></iframe>
                </div>
            </div>
            </div>
    </section> -->

    <section id="why-carpool">
        <div class="header">
            <h1>Why should I Carpool?</h1>
            <p>Carpool is revolutionary and  fun way to commute. Whether you are a car owner, bike owner or a rider, just post your ride details on nRide and we will match you with co-riders on your way</p>
        </div>

               
        <div class="row content">
            <div class="col-md-6">
                <img src="@/assets/images/share/why-carpool.jpg" class="app-image"/>
            </div>

            <div class="col-md-6 content-right">

                <div class="feature">
                    <font-awesome-icon :icon="['fa','map-marker-alt']"></font-awesome-icon>
                    <div>
                        <h2>Save upto 80% on Commute Cost</h2>
                        <p>nRiders save upto 80% per month on commute costs compared to people who opt for cabs</p>
                    </div>

                </div>

                <div class="feature">
                    <font-awesome-icon :icon="['fa','map-marked-alt']"></font-awesome-icon>
                    <div>
                        <h2>Expand your Network</h2>
                        <p>Carpool with local people from different domains and expand your network while commuting</p>
                    </div>

                </div>

                <div class="feature">
                    <font-awesome-icon :icon="['fa','info-circle']"></font-awesome-icon>
                    <div>
                        <h2>Reduce Pollution and Save Environment</h2>
                        <p>Go green with minimal carbon footprint and help reduce traffic and pollution</p>
                    </div>

                </div>

            </div>
            
            
        </div>
    </section>

<section id="experience">
        <div class="container">

            <h5>Hear from our customers</h5>
            <Carousel :items-to-show="SlideCount" class="mt-4">
                <template>
                    <Slide :key="1" class="experience-item">
                        <div class="card p-4">
                            <img class="ms-auto me-auto" src="@/assets/images/share/testimonial-photos/testi1.jpg"/>
                            <label class="text-center mb-4 fw-bold">Iyabo Aliyu</label>
                        <div class="d-flex">
                            <label>My friends and I love to go out at weekends, 
                            but none of us have a car. With NeighboursRide
                            things have changed Now we take turns 
                            making a NeighoursRide reservation for a van</label>
                        </div>
                        </div>
                    </Slide>
                    <Slide :key="2" class="experience-item">
                        <div class="card p-4">
                            <img class="ms-auto me-auto" src="@/assets/images/share/testimonial-photos/testi2.jpg"/>
                            <label class="text-center mb-4 fw-bold">Ebenezer Olorunyomi</label>
                        <div class="d-flex">
                            <label>My friends and I love to go out at weekends, 
                            but none of us have a car. With NeighboursRide
                            things have changed Now we take turns 
                            making a NeighoursRide reservation for a van</label>
                        </div>
                        </div>
                    </Slide>
                    <Slide :key="3" class="experience-item">
                        <div class="card p-4">
                            <img class="ms-auto me-auto" src="@/assets/images/share/testimonial-photos/testi3.jpg"/>
                            <label class="text-center mb-4 fw-bold">Matilda Effah</label>
                        <div class="d-flex">
                            <label>My friends and I love to go out at weekends, 
                            but none of us have a car. With NeighboursRide
                            things have changed Now we take turns 
                            making a NeighoursRide reservation for a van</label>
                        </div>
                        </div>
                    </Slide>
                    <Slide :key="4" class="experience-item">
                        <div class="card p-4">
                            <img class="ms-auto me-auto" src="@/assets/images/share/testimonial-photos/testi4.jpg"/>
                            <label class="text-center mb-4 fw-bold">Lebechi Philip</label>
                        <div class="d-flex">
                            <label>My friends and I love to go out at weekends, 
                            but none of us have a car. With NeighboursRide
                            things have changed Now we take turns 
                            making a NeighoursRide reservation for a van</label>
                        </div>
                        </div>
                    </Slide>
                </template>
                <template #addons>
                    <Navigation :index="10"/>
                </template>
            </Carousel>

            <p class="text-center mt-5">We offer a wide selection of Vehicles to choose from with top of the line care
                covered with Insurance to keep you free from all worries</p>
        </div>
    </section>
    


</template>

<script>
import 'vue3-carousel/dist/carousel.css';
    import {Carousel, Slide, Navigation} from 'vue3-carousel';

    export default {
        name: "ShareService",
        components: {
            Carousel,
            Slide,
            Navigation
        },
        computed: {
            SlideCount() {
                let width = screen.width;
                console.log(width);
                if (width < 768)
                    return 1;
                else if (width >= 768 && width < 1400)
                    return 2;
                else
                    return 3;
            }
        },
         methods: {
            handleClick() {
            this.$refs.targetRef.scrollIntoView({ behavior: 'smooth' });
    }

    }

    }
</script>

<style scoped lang="scss">
#header {
        height: 95vh;
        display: flex;
        align-items: center;
        background: url("../../assets/images/share/share-banner.webp");
        background-repeat: no-repeat;
        background-size: cover;

        .content {
            background: white;
            padding: 3rem 3rem;
            margin-left: 4rem;
            box-shadow: 0 0 16px #fffa;
            width: 40%;
            @media (max-width: 768px) {
                width: 100%;
                margin: 2rem;
                text-align: center;
                padding: 2rem 1.5rem;
            }

            h1 {
                font-size: 3rem;

                @media (max-width: 768px) {
                    font-size: 2.5rem;
                }
            }

            p {
                margin-top: 1rem;
                font-size: 1.2rem;
            }
            .logo-image{
                width : 96px;
                height: 96px;

                 @media (max-width: 768px) {
                    width : 48px;
                    height: 48px;
                }
            }

        }

    }

    #carpool {
       
        p {
            font-size: 1.3rem;
            width: 70%;
            @media (max-width: 768px) {
                width: 100%;
                text-align: center;
            }
        }

        div.content {
            margin-top: 3rem;
            padding: 1rem;

            img {
                width: 40px;
                //height: 13rem;
                object-fit: cover;
                margin-bottom: 1rem;

                @media (min-width: 1400px) {
               //     height: 18rem;

                }
            }

            p {
                width: 100%;
                font-size: 1.1rem;
                text-align: left;
            }
        }
    }

    #services {
        padding-top: 4rem;

        @media (min-width: 1500px) {
            padding-top: 8rem;
        }

        .service {
            align-items: center;
            margin-bottom: 6rem;

            img {
                width: 100%;
                height: auto;
                padding: 2rem;
            }

            .content {
                h1 {
                    font-size: 3rem;
                }
                
                p.subtitle {
                    font-size: 1.2rem;
                    width: 70%;
                    margin-top: 2rem;

                    @media (max-width: 768px) {
                        width: 100%;

                    }
                }
                .vid-height{
                    height: 200px;
                    @media (max-width: 768px) {
                        height: 40px;
                    }
                }
            }

            @media (max-width: 768px) {
                text-align: center;

            }
        }
    }

    #why-carpool {
        background: $theme-color-extra-light;
        padding: 6rem;
        margin-top: 4rem;

        @media (max-width: 768px) {
            padding: 2rem;
        }

        .header {
            text-align: center;

            p {
                margin: 1rem auto;
                width: 50%;
                font-size: 1.3rem;

                @media (max-width: 768px) {
                    width: 100%;
                }
            }
        }

        .content {
            margin-top: 4rem;
            align-items: center;

            @media (max-width: 768px) {
                margin-top: 1rem;
            }
            .app-image {
                width: 100%;
                //height: 40rem;
                object-fit: contain;
            }
            h2 {
                font-size: 1.5rem;
            }

            p {
                font-size: 1.1rem;
            }

            .content-right {
                text-align: left;
            }

            .feature {
                display: flex;
                align-items: baseline;
                margin: 4rem 0;

                svg {
                    margin: 0 1.5rem;
                    color: $theme-color-deep;
                }
            }
        }

    }

    #experience {
        margin-top: 2rem;
        padding: 6rem 0;

        h5 {
            font-weight: bold;
        }

        .experience-item {
            padding: 1rem;
            display: block;
            img {
                width: 90px;
                height: 90px;
                display: block;
                object-fit: cover;
                border-radius: 50rem;
                background: black;
            }

            div {
                display: block;

                label {
                    font-size: 1.1rem;
                }
            }
        }

        p {
            font-size: 1.5rem;
            width: 50%;
            margin: 0 auto;
            color: black;
            position: relative;
            padding-bottom: 1rem;

            &:after {
                content: "";
                position: absolute;
                width: 8rem;
                border-radius: 4rem;
                height: 4px;
                background: $theme-color;
                left: 50%;
                transform: translate(-50%, 0);
                bottom: 0;
            }

            @media (max-width: 768px) {
                width: 100%;
            }
        }

    }
</style>