<template>
    <section id="header">
        <div class="content">
      <img class = "logo-image" src="@/assets/logo_transparent.png" />
      <h1>Reserve a car for corporate use</h1>
      <p>Contact us for corporate rates and get the best deal</p>
      <span class="more" @click="handleClick()">Learn more about this application</span>
    </div>
    </section>
  <section>
    <div class="card" ref="targetRef">
      <h3 class="text-center text-black">Book your Corporate Ride/Driver</h3>
      <button for="firstname" class="text-center" @click="openEmail">For any kind of support, contact &nbsp; <span class="underline">info@nride.co</span></button>
      <form @submit="checkForm" class="mt-3">
        <div class="d-flex">
          <div class="flex-fill">
            <label for="firstname" class="form-label">First Name</label>
            <input
              type="text"
              class="form-control"
              id="firstname"
              required
              placeholder="First Name"
              aria-describedby="firstname"
              v-model.trim="firstname"
            />
            <error
              v-if="(!this.firstname || this.firstname === '') && isSubmitClick"
              >* Required</error
            >
          </div>
          <div class="ms-3 flex-fill">
            <label for="lastname" class="form-label">Last Name</label>
            <input
              type="text"
              class="form-control"
              id="lastname"
              required
              placeholder="lastname"
              aria-describedby="lastname"
              v-model.trim="lastname"
            />
            <error
              v-if="(!this.lastname || this.lastname === '') && isSubmitClick"
              >* Required</error
            >
          </div>
        </div>
        <div class="d-flex">
          <div class="flex-fill">
            <label for="email" class="form-label">Email </label>
            <input
              type="text"
              class="form-control"
              id="email"
              required
              placeholder="Email"
              aria-describedby="emailHelp"
              v-model.trim="email"
              @blur="validateEmail"
            />
            <error v-if="this?.msg['email']">{{ this?.msg["email"] }}</error>
          </div>
          <div class="ms-3 flex-fill">
            <label for="phone" class="form-label">Phone</label>
            <input
              type="text"
              @input="acceptNumber"
              required
              class="form-control"
              id="phone"
              placeholder="Phone"
              aria-describedby="emailHelp"
              v-model.trim="phone"
            />
          </div>
        </div>

        <div class="mb-3">
          <label for="companyname" class="form-label"
            >Company or Organization Name</label
          >
          <input
            type="text"
            class="form-control"
            placeholder="Company or Organization Name"
            id="companyname"
            required
            v-model.trim="companyname"
            aria-describedby="companyname"
          />
          <error
            v-if="
              (!this.companyname || this.companyname === '') && isSubmitClick
            "
            >* Required</error
          >
        </div>

        <div class="mb-3">
          <label for="address" class="form-label">Address</label>
          <textarea
            type="text"
            class="form-control"
            placeholder="Address"
            id="address"
            required
            v-model.trim="address"
            aria-describedby="address"
          ></textarea>
          <error v-if="(!this.address || this.address === '') && isSubmitClick"
            >* Required</error
          >
        </div>

        <div class="d-flex">
          <div class="flex-fill">
            <label for="service" class="form-label">
              What service would you require today?</label
            >
            <select
              class="form-select"
              v-model="service"
              required
              aria-label="Default select example"
            >
              <option selected value="">All selected</option>
              <option value="Rental">Rental</option>
              <option value="Driver">Driver</option>
            </select>
            <error
              v-if="(!this.service || this.service === '') && isSubmitClick"
              >* Required</error
            >
          </div>
          <div class="ms-3 flex-fill">
            <label for="ride" class="form-label"
              >What class of ride would you require for your rental?</label
            >
            <select
              class="form-select"
              aria-label="Default select example"
              v-model="ride"
            >
              <option selected value="">All selected</option>
              <option value="Truck">Truck</option>
              <option value="Sedan">Sedan</option>
              <option value="Sport">Sport</option>
              <option value="Lorry">Lorry</option>
              <option value="Vintage">Vintage</option>
            </select>
            <error v-if="(!this.ride || this.ride === '') && isSubmitClick"
              >* Required</error
            >
          </div>
        </div>

        <div class="d-flex">
          <div class="flex-fill">
            <label for="cars" class="form-label"
              >Number of Cars you wish to Book</label
            >
            <input
              type="number"
              class="form-control"
              id="cars"
              placeholder="Number of Cars"
              aria-describedby="cars"
              v-model.trim="cars"
            />
            <error v-if="(!this.cars || this.cars === '') && isSubmitClick"
              >* Required</error
            >
          </div>
          <div class="ms-3 flex-fill">
            <label for="yourrental" class="form-label"
              >What class of ride would you require for your rental?</label
            ><br />
            <input
              type="radio"
              id="yes"
              name="fav_language"
              @input="(event) => (yourrental = event.target.value)"
              value="YES"
            />
            <label for="yes">YES</label><br />
            <input
              type="radio"
              id="no"
              name="fav_language"
              @input="(event) => (yourrental = event.target.value)"
              value="NO"
            />
            <label for="no">NO</label><br />
            <select
              class="form-select"
              v-model="yourrentalVal"
              v-if="yourrental === 'YES'"
              aria-label="Default select example"
            >
              <option selected value="">select a value</option>
              <option selected value="All">All selected</option>
              <option value="Truck">Truck</option>
              <option value="Sedan">Sedan</option>
              <option value="Sport">Sport</option>
              <option value="Lorry">Lorry</option>
              <option value="Vintage">Vintage</option>
            </select>
          </div>
        </div>

        <p v-show="!formIsValid" class="error">{{ errorMsg }}</p>
        <button type="submit" class="btn btn-theme mt-3">Submit</button>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  name: "CorporateService",
  data() {
    return {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      companyname: "",
      address: "",
      service: "",
      ride: "",
      yourrental: "",
      yourrentalVal: "",
      formIsValid: true,
      errorMsg: "",
      cars: "",
      isSubmitClick: false,
      msg: { email: "" },
    };
  },
  methods: {
    validateEmail() {
      if (
        /^[a-zA-Z0-9_\-.]+[@][a-z]+[.]+((com)|(co.in)|(net)|(in)|(org)|(info)|(biz))$/.test(
          this.email
        )
      ) {
        // this.email="valid";
        this.msg["email"] = "";
      } else {
        this.msg["email"] = "Please enter a valid email address";
        // this.email="invalid";
      }
    },
    acceptNumber() {
      var x = this.phone.replace(/\D/g, "").match(/(\d{0,10})/);
      this.phone = x[1] ? x[1] : "";
    },
    checkForm(e) {
      const x = {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        phone: this.phone,
        companyname: this.companyname,
        address: this.address,
        service: this.service,
        ride: this.ride,
        yourrental:
          this.yourrental === "YES" ? this.yourrentalVal : this.yourrental,
        cars: this.cars,
      };
      this.isSubmitClick = true;
      console.log(x);
      if (
        Object.values(x).every((v) => (v != null && v != "") || v?.length > 0)
      ) {
        //API will be called here
        console.log(
          [x.firstname, x.lastname].every((v) => v != null && v?.length > 0)
        );
        console.log([x.email].every((v) => v != null && v?.length > 0));
        console.log([x.phone].every((v) => v != null && v?.length > 0));
        console.log([x.companyname].every((v) => v != null && v?.length > 0));
        console.log([x.address].every((v) => v != null && v?.length > 0));
        console.log([x.service].every((v) => v != null && v?.length > 0));
        console.log([x.ride].every((v) => v != null && v?.length > 0));
        console.log([x.yourrental].every((v) => v != null && v?.length > 0));
        console.log([x.cars].every((v) => v != null && v?.length > 0));
      }

      e.preventDefault();
    },
      openEmail() { 
      window.location.href = "mailto:info@nride.co";
    },
     handleClick() {
            this.$refs.targetRef.scrollIntoView({ behavior: 'smooth' });
    }

    }
};
</script>

<style scoped lang="scss">
section {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}
#header {
  height: 95vh;
  display: flex;
  align-items: center;
 background: url("../../assets/images/corporate/corporate-banner.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  
  .content {
    background: white;
    padding: 3rem 3rem;
   // margin-left: 52%;
    margin-left: 4rem;
    box-shadow: 0 0 16px #fffa;
    width: 40%;
    @media (max-width: 768px) {
      width: 100%;
      margin: 2rem;
      text-align: center;
      padding: 2rem 1.5rem;
    }

    h1 {
      font-size: 3rem;

      @media (max-width: 768px) {
        font-size: 2.5rem;
      }
    }

    p {
      margin-top: 1rem;
      font-size: 1.2rem;
    }
  .logo-image{
                width : 96px;
                height: 96px;

                 @media (max-width: 768px) {
                    width : 48px;
                    height: 48px;
                }
            }
    .width {
      width: 100%;
    }
  }
}
.card {
  width: 45%;
  margin: 0 auto;
  padding: 3rem 2rem;
  box-shadow: 0 0 16px #0003;
  @media (max-width: 768px) {
    width: 100%;
    margin: 2rem;
    text-align: center;
    padding: 2rem 1.5rem;
  }
  h1 {
    text-align: center;
  }
}

label {
  color: #015032;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
}

input{
    margin-bottom: 1rem;
}

error {
  color: red;
  font-size: 0.8rem;
}

.underline{
  text-decoration: underline;
}

button{
  color: #e25555;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  cursor: pointer;
  border: none;
  background: white;
}

</style>