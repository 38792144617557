<template>
    <footer>
        <div class="container pb-5">
            <div class="row">
                <div class="col-md-6">
                    <router-link class="navbar-brand" :to="{name:'Home'}"><h1>nRide</h1></router-link>
                    <h6>A subsidiary of Neighbours Platform</h6>
                    <div class="mt-5">
                        <h5>Support</h5>
                        <div class="office mt-5">
                            <h6>US Office</h6>
                            <table width="80%">
                                <tr>
                                    <td>Timings</td>
                                    <td>09:00 - 05:00pm Pacific Time</td>
                                </tr>
                                <!-- <tr>
                                    <td>Phone</td>
                                    <td>+1-222-333345</td>
                                </tr> -->
                                <tr>
                                    <td>Email</td>
                                    <td>info@nride.co</td>
                                </tr>
                            </table>
                        </div>

                        <!-- <div class="office mt-5">
                            <h6>Nigeria Office</h6>
                            <table width="80%">
                                <tr>
                                    <td>Timings</td>
                                    <td>09:00 - 05:00pm West Africa Time</td>
                                </tr>
                                <tr>
                                    <td>Phone</td>
                                    <td>+1-222-333345</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>nigeriasupport@nride.co</td>
                                </tr>
                            </table>
                        </div> -->
                    </div>
                </div>
                <div class="col-md-2 col-sm-6 col-xs-6">
                    <h6>Services</h6>
                    <ul>
                        <li><a href="/services/rentals">Rentals</a></li>
                        <li><a href="/services/shares">Share Rides</a></li>
                        <li><a href="/services/host">Hosts</a></li>
                        <li><a href="/services/corporate">Corporate</a></li>
                    </ul>

                </div>

                <!-- <div class="col-md-2  col-sm-6  col-xs-6">
                    <h6>Experiences</h6>
                    <ul>
                        <li>Caravan</li>
                        <li>Classic</li>
                        <li>Convertible</li>
                        <li>Hatchback</li>
                        <li>Luxury</li>
                        <li>Micro</li>
                        <li>Minivan</li>
                        <li>Sedan</li>
                        <li>Sports</li>
                        <li>SUV</li>
                        <li>Traveller</li>
                        <li>Truck</li>

                    </ul>
                </div> -->

                <div class="col-md-2">
                    <h6>Polices</h6>
                    <ul>
                        <li>
                            <router-link :to="{name:'TermsConditions'}">Terms & Conditions</router-link>
                        </li>
                        <li>
                            <router-link :to="{name:'PrivacyPolicy'}">Privacy Policy</router-link>
                        </li>
                        <li>Payment Policy</li>
                        <li>Cancellation Policy</li>
                        <li>Insurance Policy</li>
                        <li>Safety Policy</li>

                    </ul>
                </div>
            </div>
<!--
            <div class="social">
                <ul>
                    <li><a href="">
                        <font-awesome-icon :icon="['fab','facebook-f']"/>
                    </a></li>

                    <li><a href="">
                        <font-awesome-icon :icon="['fab','linkedin-in']"/>
                    </a></li>

                    <li><a href="">
                        <font-awesome-icon :icon="['fab','twitter']"/>
                    </a></li>

                    <li><a href="">
                        <font-awesome-icon :icon="['fab','youtube']"/>
                    </a></li>

                    <li><a href="">
                        <font-awesome-icon :icon="['fab','instagram']"/>
                    </a></li>
                </ul>

            </div> -->
        </div>
    </footer>

    <div class="footer-2">
        <div class="container d-flex justify-content-between">
            <span>Copyright &copy; 2022 | All Rights Reserved.</span>
            <span>Designed & Developed by <a href="https://www.turtleye.com" class="turtleye" target="_blank">Turtleye Tech</a> </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "footer-default"
    }
    
</script>

<style scoped lang="scss">
    footer {
        padding-top: 6rem;
        background: $theme-color-extra-light;

        h1 {
            font-size: 4rem;
            font-weight: 900;
        }

        h6 {
            font-weight: 600;
            letter-spacing: 0.05rem;
        }

        ul {
            list-style: none;
            padding-left: 0;
            margin-top: 1rem;

            li {
                padding: 0.4rem 0;
                color: black;
                text-transform: uppercase;
                font-size: 0.85rem;
                letter-spacing: 0.05rem;
                cursor: pointer;
                transition: all ease-in-out 300ms;

                &:hover {
                    font-weight: 600;
                }

            }
        }


        @media (max-width: 768px) {
            div.row {
                [class *='col'] {
                    margin-bottom: 2rem;
                }
            }
        }

    }

    .footer-2 {
        background: $theme-color-light;
        padding: 1.2rem;
        color: black;
        font: $heading-font;
        letter-spacing: 0.02rem;
    }

    .turtleye {
        color: $theme-color-deep;
    }

    .social {
        text-align: right;
        @media (max-width: 768px) {
            text-align: center;
        }

        li {
            height: 2.7rem;
            width: 2.7rem;
            border: 1px solid $text-color-light;
            border-radius: 50%;
            text-align: center;
            font-size: 1.2rem;
            transition: all ease-in-out 400ms;
            display: inline-block;
            margin: 0 0.5rem;

            a {
                color: $text-color-light;
                transition: all ease-in-out 400ms;
            }

            &:hover {
                background: $theme-color-deep;
                border: 1px solid $theme-color-deep;

                a {
                    color: white;
                }

            }
        }

    }
</style>